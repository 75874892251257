import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list"]
  static values = { listUrl: String }

  appendList(html) {
    const fragment = document
      .createRange()
      .createContextualFragment(html);
    this.element.appendChild(fragment);
  }

  display(event) {
    event.preventDefault()
    const closeList = (event) => {
      if (!this.listTarget.contains(event.target)) {
        this.listTarget.remove()
        document.removeEventListener('click', closeList)
      }
    }

    fetch(this.listUrlValue)
      .then((response) => response.text())
      .then((html) => this.appendList(html))
      .then(() => document.addEventListener('click', closeList));
  }
}
