import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "massInput"]

  handleChange() {
    if (this.massInputTarget.value) {
      this.formTarget.submit();
    }
  }
}
