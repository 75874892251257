import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "rawMaterial", "solutionMaterial", "title", "operationName" ]

  connect () {
    if (this.operationName) {
      if (this.operationNameTarget.value == "direct_test" ) {
        this.titleTarget.value = "Test direct"
      }
    }
  }

  handleOperationOptionsChange() {
    this.element.submit();
  }

  handleTestNamingRaw() {
    if (this.operationNameTarget.value == "test" ) {
      const materialValue = this.rawMaterialTarget.value;

      if (materialValue) {
        const sliceIndex = (materialValue.length === 13 || materialValue.length > 14) ? -2 : -1
        this.solutionMaterialTarget.selectedIndex = -1;
        this.titleTarget.value = `Test ${materialValue.slice(sliceIndex).toUpperCase()}`;
      }
    }
  }

  handleTestNamingSolution() {
    if (this.operationNameTarget.value == "test" ) {
      const materialValue = this.solutionMaterialTarget.value;

      if (materialValue) {
        const sliceIndex = (materialValue.length === 13 || materialValue.length > 14) ? -2 : -1
        this.rawMaterialTarget.selectedIndex = -1;
        this.titleTarget.value = `Test ${materialValue.slice(sliceIndex).toUpperCase()}`;
      }
    }
  }
}
