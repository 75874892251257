import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "button", "select"]

  handleFreeButton() {
    if (this.validForm()) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  handleShipmentButton() {
    const signed = this.selectTarget.selectedIndex > 0

    if (this.validForm() && signed) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  validForm() {
    const checkboxes = this.checkboxTargets.map((checkbox) => {
      return checkbox.checked
    })

    return checkboxes.includes(true);
  }

  enableButton() {
    this.buttonTarget.classList.remove("btn-disabled")
    this.buttonTarget.classList.add("btn-primary")
    this.buttonTarget.disabled = false
  }

  disableButton() {
    this.buttonTarget.classList.add("btn-disabled")
    this.buttonTarget.classList.remove("btn-primary")
    this.buttonTarget.disabled = true
  }
}
