import { Controller } from "stimulus";
import Swal from 'sweetalert2';
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    title: String,
    firstTitle: String,
    secondTitle: String,
    mainText: String,
    firstMainText: String,
    secondMainText: String,
    ok: String,
    validationText: String,
    confirmButton: String,
    denyText: String,
    icon: String,
    path: String,
    method: String
  }

  validation() {
    Swal.fire({
      title: this.titleValue,
      showCancelButton: true,
      confirmButtonColor: "#1B86EE",
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: this.confirmButtonValue,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          this.ok,
          this.validationTextValue
          ).then(() => {
          if (this.pathValue) {
            Rails.ajax({
              url: this.pathValue,
              type: this.methodValue
            })
          }
        })
      }
    })
  }

  destock() {
    Swal.fire({
      title: this.titleValue,
      text: this.mainTextValue,
      showCancelButton: true,
      confirmButtonText: this.confirmButtonValue,
      cancelButtonText: "Retour",
      confirmButtonColor: "#1B86EE",
      cancelButtonColor: "#d33"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          this.ok,
          this.validationTextValue
          ).then(() => {
          if (this.pathValue) {
            Rails.ajax({
              url: this.pathValue,
              type: this.methodValue
            })
          }
        })
      }
    })
  }

  rawMaterialSelection() {
    Swal.fire({
      title: this.titleValue,
      text: this.mainTextValue,
      showCloseButton: true,
      confirmButtonColor: '#1B86EE',
      confirmButtonText: 'Modifier',
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.pathValue) {
          Rails.ajax({
            url: this.pathValue,
            type: this.methodValue
          })
        }
      }
    })
  }

  rawMaterialOrder() {
    Swal.fire({
      title: this.titleValue,
      text: this.mainTextValue,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonColor: "#1B86EE",
      denyButtonColor: "#1B86EE",
      cancelButtonColor: '#d33',
      confirmButtonText: this.confirmButtonValue,
      denyButtonText: 'Mettre en commande',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          this.ok,
          this.validationTextValue
          ).then(() => {
            if (this.pathValue) {
              Rails.ajax({
                url: this.pathValue,
                type: this.methodValue
              })
            }
          })
        } else if (result.isDenied) {
          Swal.fire(
            this.ok,
            this.denyTextValue
            ).then(() => {
              if (this.pathValue) {
                Rails.ajax({
                  url: this.pathValue,
                  type: this.methodValue
                })
              }
        })
      }
    })
  }

  doubleInformation() {
    Swal.fire({
      title: this.firstTitleValue,
      text: this.firstMainTextValue,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#1B86EE',
      confirmButtonText: 'Suivant'
    }).then((result) => {
      Swal.fire({
        title: this.secondTitleValue,
        text: this.secondMainTextValue,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#1B86EE',
        confirmButtonText: 'Suivant'
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.pathValue) {
            Rails.ajax({
              url: this.pathValue,
              type: this.methodValue
            })
          }
        }
      })
    })
  }

  singleInformation() {
    Swal.fire({
      title: this.titleValue,
      text: this.mainTextValue,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#1B86EE',
      confirmButtonText: 'Suivant'
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.pathValue) {
          Rails.ajax({
            url: this.pathValue,
            type: this.methodValue
          })
        }
      }
    })
  }

  simple() {
    Swal.fire(
      this.titleValue,
      this.mainTextValue,
      this.iconValue
    )
  }

  confirmBeforeSubmit(event) {
    event.preventDefault()
    Swal.fire({
      title: this.titleValue,
      text: this.mainTextValue,
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonText: "Retour",
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if(result.isConfirmed) {
        this.element.submit()
      }
    })
  }

}
