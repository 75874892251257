import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "aside", "main", "button" ];

  toggle() {
    this.asideTarget.classList.remove("hidden");
    this.asideTarget.classList.toggle("w-1/6");

    if (this.asideTarget.classList.contains("w-1/6")) {
      this.asideTarget.classList.add("slide-in-left")
      this.asideTarget.classList.remove("slide-out-left");
      this.buttonTarget.innerHTML = `<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>`
      this.buttonTarget.parentNode.classList.remove('ml-6')
    } else {
      this.asideTarget.classList.add("slide-out-left");
      this.asideTarget.classList.remove("slide-in-left");
      this.buttonTarget.innerHTML = `<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>`
      this.buttonTarget.parentNode.classList.add('ml-6')
    }

    this.mainTarget.classList.toggle("w-5/6");
    this.mainTarget.classList.toggle("w-full");
  }
}
