import { Controller } from "stimulus"
import Rails from "@rails/ujs";


export default class extends Controller {
  // static targets = ["checkbox"];

  // Not used for now
  // checkAll(event) {
  //   this.checkboxTargets.forEach(checkbox => {
  //     checkbox.checked = event.currentTarget.checked;
  //     TODO check all box in back
  //   });
  // }

  check(event) {
    // Step 1 :
      // Get the form from where the input is from
    const form = event.target.form

    // Step 2 :
      // Submit the form in order to create the bottling operation (see operation_bottling_controller)
    form.submit();
  }

  uncheck(event) {

    const checkbox = event.target

    // Step 1 :
      // Here we can't use classic form submit because we're deleting an operation through a link_to rail helper and
      // not through a form
      // Pay attention also that we use datasets instead of stimulus values. The reason is that we have multiple
      // checkboxes with a different value for any of each, and stimulus values can only handle one value per controller
    Rails.ajax({
      url:  checkbox.dataset.path,
      type: checkbox.dataset.method
    })
  }
}
