import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  // load content only when element is within viewport
  connect() {
    // `bind(this)` is here so `this` still refers to
    // controller when callback is triggered
    let callback = this.loadAndDisconnect.bind(this);
    let observer = new IntersectionObserver(callback);
    observer.observe(this.element);
  }

  load() {
    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => this.element.innerHTML = html)
  }

  loadAndDisconnect([element], observer) {
    if (element.isIntersecting) { // if element in viewport
      requestIdleCallback(this.load.bind(this)) // do this when browser is idle
      observer.disconnect(); // stop observing
    }
  }
}
