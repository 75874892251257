import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "commentsList", "noContent"]

  connect() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.commentsListTarget.scroll({ top: 0 });
  }

  deleteNoContent() {
    if (this.hasNoContentTarget) {
      this.noContentTarget.remove();
    }
  }
}
