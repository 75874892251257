import { Controller } from "stimulus";
import Swal from 'sweetalert2';
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    title: String,
    mainText: String,
    ok: String,
    confirmButton: String,
    icon: String,
    path: String,
    method: String
  }

  connect() {
    this.confirmBeforeSubmit();
  }

  confirmBeforeSubmit() {
    Swal.fire({
      title: this.titleValue,
      text: this.mainTextValue,
      icon: 'warning',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if(result.isConfirmed) {
        Rails.ajax({
          url: this.pathValue,
          type: this.methodValue
        })
      }
    })
  }
}
