import Rails from "@rails/ujs";
import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      handle: ".drag-handler",
      ghostClass: "bg-blue-100",
      onEnd: this.updatePosition.bind(this) // conserve `this` as stimulus controller
    })
  }

  updatePosition(event) {
    const id = event.item.dataset.id
    const data = new FormData()
    data.append("position", event.newIndex + 1)

    Rails.ajax({
      url: this.urlValue.replace(":id", id),
      type: 'PATCH',
      data: data
    })
  }
}
