import { Controller } from "stimulus"

export default class extends Controller {

  reset() {
    this.element.reset();
  }

  submit() {
    this.element.submit();
  }
}
