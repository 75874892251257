import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    validText: String,
    withErrorText: String,
    noValueText: String,
    targetConcentration: Number
  }

  static targets = [
    "compensation",
    "compensationCheckbox",
    "density",
    "densityCheckbox",
    "submitButton",
    "targetConcentrationInput",
    "targetConcentrationMessage"
  ]

  connect() {
    this.checkCompensation();
    this.checkDensity();
    this.generateValidation();
  }

  checkCompensation() {
    if (this.compensationCheckboxTarget.checked) {
      this.compensationTarget.classList.remove("hidden");
    } else {
      this.compensationTarget.classList.add("hidden");
    }
  }

  checkDensity() {
    this.densityTargets.forEach(density => {
      if (this.densityCheckboxTarget.checked) {
        density.classList.remove("hidden");
      } else {
        density.classList.add("hidden");
      }
    });
  }

  generateValidation(){
    const value = this.targetConcentrationInputTarget.value;
    const targetValue = this.targetConcentrationValue;

    if (value >= targetValue) {
      var message = this.validTextValue;
      this.targetConcentrationInputTarget.classList.remove("with_error");
      this.targetConcentrationInputTarget.classList.add("valid");
      this.submitButtonTarget.classList.remove("btn-disabled");
      this.submitButtonTarget.classList.add("btn-primary");
      this.targetConcentrationMessageTarget.classList.remove("text-red-600");
      this.targetConcentrationMessageTarget.classList.add("text-green-600");
    } else {
      this.submitButtonTarget.classList.remove("btn-primary");
      this.submitButtonTarget.classList.add("btn-disabled");
      this.targetConcentrationInputTarget.className = "";
      this.targetConcentrationMessageTarget.classList.remove("text-green-600");
      if (!value) {
        this.targetConcentrationMessageTarget.classList.remove("text-red-600");
        var message = this.noValueTextValue;
      } else {
        var message = this.withErrorTextValue;
        this.targetConcentrationInputTarget.classList.add("with_error");
        this.targetConcentrationMessageTarget.classList.add("text-red-600");
      }
    }

    this.targetConcentrationMessageTarget.innerHTML = message
  }
}
