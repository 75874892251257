import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  check(event) {

    const position = parseInt(event.target.dataset.position)
    const product = event.target.dataset.product

    if (event.target.checked) {
      this.checkboxTargets.forEach(checkbox => {
        if (checkbox.dataset.product == product) {
          checkbox.checked = true
          checkbox.parentNode.parentNode.classList.add("bg-blue-100")
          event.target.parentNode.parentNode.classList.add("bg-blue-100")
        }
        if (checkbox.dataset.position <= position ) {
          checkbox.checked = true
          checkbox.parentNode.parentNode.classList.add("bg-blue-100")
          event.target.parentNode.parentNode.classList.add("bg-blue-100")
         }
      });

    } else {

      this.checkboxTargets.forEach(checkbox => {
        event.target.parentNode.parentNode.classList.remove("bg-blue-100")
        if (checkbox.dataset.position >= position ) {
          checkbox.checked = false
          checkbox.parentNode.parentNode.classList.remove("bg-blue-100")
          event.target.parentNode.parentNode.classList.remove("bg-blue-100")
        }
      });
    }
  }
}
